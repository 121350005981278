// Setting Dark Mode on root

const darkModeToggle = () => {

    const rootElement = document.getElementById('root')

    if (localStorage.getItem('darkMode') === 'true') {
        rootElement?.classList.add('dark')
    } else {
        rootElement?.classList.remove('dark')
    }

}

export default darkModeToggle