import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import type { RootState } from '../store'
import serverLinks from '../../Constants'

export interface IContestRewind {
    _id: string
    contestTitle: string
    contestDate: Date
    contestDuration: number
    contestPattern: string
}

interface IContestQuestion {
    _id: string,
    qOptions: string[],
    qSubject: string,
    qDescription: string
}

export interface IContestDetail {
    contestTitle: string,
    contestQuestions: IContestQuestion[],
    contestStartTime: Date | null,
    contestEndTime: Date | null,
    contestPattern: string
}

interface IContestState {
    contestRewind: IContestRewind[]
    isContestRewindLoading: boolean
    contestRewindError: null | string,
    contestDetail: IContestDetail,
    contestDetailError: null | string,
    isContestDetailLoading: boolean,
    currentSubject: 'dilr' | 'varc' | 'qa',
    currentQuestionIndex: number
}



const initialState: IContestState = {
    contestRewind: [],
    isContestRewindLoading: true,
    contestRewindError: null,
    contestDetail: {
        contestEndTime: null,
        contestPattern: '',
        contestQuestions: [{
            _id: '',
            qOptions: [],
            qSubject: '',
            qDescription: ''
        }],
        contestStartTime: null,
        contestTitle: ''
    },
    contestDetailError: null,
    isContestDetailLoading: true,
    currentSubject: 'dilr',
    currentQuestionIndex: 0
}

export const fetchContestRewind = createAsyncThunk('contest/contestRewind', async () => {
    const result = fetch(serverLinks.fetchContestRewind).then((response) => response.json())
    return result
})


export const fetchContestDetail = createAsyncThunk('/contest/contestDetail', async (contestId: string) => {
    const result = fetch(serverLinks.fetchContestDetail, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            contestId: contestId
        })
    })
        .then(response => response.json())
        .catch(error => console.error(error))

    return result;
})

const contestSlice = createSlice({
    name: 'contest',
    initialState,
    reducers: {
        setCurrentSubject: (state, action) => {
            state.currentSubject = action.payload
        },
        setCurrentQuestion: (state, action) => {
            console.log('[setCurrentQuestion Reducer] action.payload:', action.payload)
            state.currentQuestionIndex = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchContestRewind.fulfilled, (state, action) => {
            if (action.payload && action.payload.contestRewind) {
                state.contestRewind = action.payload.contestRewind
            } else {
                state.contestRewindError = 'Unable to load contest rewind'
            }
            state.isContestRewindLoading = false
        })

        builder.addCase(fetchContestRewind.pending, (state) => {
            state.isContestRewindLoading = true
        })

        builder.addCase(fetchContestRewind.rejected, (state) => {
            state.contestRewindError = 'Unable to load contest rewind'
            state.isContestRewindLoading = false
        })

        builder.addCase(fetchContestDetail.fulfilled, (state, action) => {
            if (action.payload!) {
                state.contestDetail = action.payload
            } else {
                state.contestDetailError = 'Unable to load contest details'
            }
            state.isContestDetailLoading = false
        })

        builder.addCase(fetchContestDetail.pending, (state) => {
            state.isContestDetailLoading = true
        })

        builder.addCase(fetchContestDetail.rejected, (state) => {
            state.isContestDetailLoading = false
            state.contestDetailError = 'Fetch Contest Detail Rejected from server'
        })
    }
})

export const { setCurrentSubject, setCurrentQuestion } = contestSlice.actions

// Contest Rewind Selectors

export const selectContestRewind = (state: RootState) => state.contest.contestRewind

export const selectIsContestRewindLoading = (state: RootState) => state.contest.isContestRewindLoading

export const selectContestRewindError = (state: RootState) => state.contest.contestRewindError

// Contest Detail Selectors

export const selectContestTitle = (state: RootState) => state.contest.contestDetail.contestTitle

export const selectContestDetailError = (state: RootState) => state.contest.contestDetailError

export const selectIsContestDetailLoading = (state: RootState) => state.contest.isContestDetailLoading

export const selectContestSubjects = (state: RootState) => {
    const contestSubjects: Set<string> = new Set()

    state.contest.contestDetail.contestQuestions.forEach(question => {
        contestSubjects.add(question.qSubject)
    })

    return Array.from(contestSubjects)
}

const selectSubjectWiseQuestions = (state: RootState): IContestQuestion[] => {
    let subjectWiseQuestions: IContestQuestion[] = []
    if (state.contest.currentSubject) {
        state.contest.contestDetail.contestQuestions.forEach(question => {
            if (question.qSubject === state.contest.currentSubject) {
                subjectWiseQuestions.push(question)
            }
        })
    }
    return subjectWiseQuestions
}

export const selectSubjectWiseQuestionsCount = createSelector(selectSubjectWiseQuestions, (questionList: IContestQuestion[]) => {
    return questionList.length
})

export const selectCurrentQuestionIndex = (state: RootState) => state.contest.currentQuestionIndex

export const selectCurrentQuestionDescription = createSelector(selectSubjectWiseQuestions, selectCurrentQuestionIndex, (questionList: IContestQuestion[], index: number) => {
    if (questionList && questionList.length > 0) {
        return questionList[index].qDescription
    } else {
        return ''
    }
})

export const selectCurrentQuestionOptions = createSelector(selectSubjectWiseQuestions, selectCurrentQuestionIndex, (questionList: IContestQuestion[], index: number) => {
    if (questionList && questionList.length > 0) {
        console.log('[selectCurrentQuestionOptions] questionList', questionList)
        return questionList[index].qOptions
    } else {
        console.log('[selectCurrentQuestionOptions] unable to fetch current question desciption')
        return ''
    }
})

export default contestSlice.reducer
