import { configureStore } from '@reduxjs/toolkit'
import { userReducer, questionReducer, submissionReducer, contestReducer, globalReducer } from './reducers'

// Redux Persist
import storage from 'redux-persist/lib/storage'
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist'


const questionReducerPersistConfig = {
    key: 'questions',
    version: 1,
    storage
}

const store = configureStore({
    reducer: {
        user: userReducer,
        submissions: submissionReducer,
        // @ts-ignore
        questions: persistReducer(questionReducerPersistConfig, questionReducer),
        contest: contestReducer,
        global: globalReducer
    },
    devTools: process.env.NODE_ENV !== 'production',
    // Ignoring all the non-serizable action types
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
            }
        })
})

export default store

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
