// Higher Order Component to recover from errors gracefully
// This component covers the entire application and is rendered only when an error occurs
// Otherwise just renders it's children components
// This has to be a class based component - cannot be a functional component

import { Component, ErrorInfo } from 'react'
import { Link } from 'react-router-dom'

interface PropInterface {}

class ErrorBoundary extends Component {
    constructor(props: PropInterface) {
        super(props)
        this.state = {
            hasError: false
        }
    }

    static getDerivedStateFromError(error: Error) {
        return { hasError: true }
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error('Error Caught in Error Boundary')
        console.error(error)
        console.error(errorInfo)
    }

    render() {
        // @ts-ignore
        if (this.state.hasError) {
            return (
                <div className="container">
                    <h3>Error has occured</h3>
                    <p>
                        <Link to="/">Click Here</Link> to go back to safety
                    </p>
                </div>
            )
        } else {
            return this.props.children
        }
    }
}

export default ErrorBoundary
