import { createSlice } from '@reduxjs/toolkit';
import darkModeToggle from '../../utils/darkModeToggle';
import type { RootState } from '../store';


export interface globalState {
    isIOSUser: boolean
    displayIOSCheckModal: boolean,
    darkMode: boolean
}

export const initialState: globalState = {
    isIOSUser: false,
    displayIOSCheckModal: false,
    darkMode: false
}

const globalSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        setIsIOSUser: (state, action) => {
            if (action && action.payload) {
                state.isIOSUser = action.payload
            }
        },
        setDisplayIOSCheckModal: (state) => {
            state.displayIOSCheckModal = true
        },
        resetDisplayIOSCheckModal: (state) => {
            state.displayIOSCheckModal = false
        },
        toggleDarkMode: (state) => {
            state.darkMode = !state.darkMode
            localStorage.setItem('darkMode', state.darkMode.toString())
        },
        checkDarkMode: (state) => {
            if (localStorage.getItem('darkMode') === 'true') {
                state.darkMode = true
            } else {
                state.darkMode = false
            }
        }
    }
})

export const { setIsIOSUser, setDisplayIOSCheckModal, resetDisplayIOSCheckModal, toggleDarkMode, checkDarkMode } = globalSlice.actions

export const selectIsIOSUser = (state: RootState) => state.global.isIOSUser

export const selectDisplayIOSCheckModal = (state: RootState) => state.global.displayIOSCheckModal

export const selectDarkMode = (state: RootState) => state.global.darkMode

export default globalSlice.reducer