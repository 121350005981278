import dotenv from 'dotenv'

dotenv.config({ path: __dirname + './env' })

export const TRACKING_ID = 'UA-129067814-2'

type urls = {
    backendBase: string
    googleLogin: string
    getUser: string
    userLogout: string
    questionList: string
    question: string
    submitQuestion: string
    userSubmission: string
    likeQuestion: string
    fetchRecentSubmissions: string
    fetchUpcomingContest: string
    fetchGlobalRankSummary: string
    fetchContestRewind: string,
    fetchContestDetail: string
}

const productionUrls: urls = {
    backendBase: 'https://api.catprep.in',
    googleLogin: 'https://api.catprep.in/auth/google/',
    getUser: 'https://api.catprep.in/auth/getUser/',
    userLogout: 'https://api.catprep.in/auth/logout/',
    questionList: 'https://api.catprep.in/api/questionList',
    question: 'https://api.catprep.in/api/questionList/question',
    submitQuestion: 'https://api.catprep.in/api/submitQuestion/submit',
    userSubmission: 'https://api.catprep.in/api/submitQuestion/usersubmission',
    likeQuestion: 'https://api.catprep.in/api/questionList/likeQuestion',
    fetchRecentSubmissions: 'https://api.catprep.in/api/submitQuestion/recentSubmissions',
    fetchUpcomingContest: 'https://api.catprep.in/api/contest/upcoming',
    fetchContestRewind: 'https://api.catprep.in/api/contest/contestRewind',
    fetchGlobalRankSummary: 'https://api.catprep.in/api/contest/globalRankingSummary',
    fetchContestDetail: 'https://api.catprep.in/api/contest/contestDetail'
}

const developmentUrls: urls = {
    backendBase: 'http://localhost:1337',
    googleLogin: 'http://localhost:1337/auth/google',
    getUser: 'http://localhost:1337/auth/getUser',
    userLogout: 'http://localhost:1337/auth/logout',
    questionList: 'http://localhost:1337/api/questionList',
    question: 'http://localhost:1337/api/questionList/question',
    submitQuestion: 'http://localhost:1337/api/submitQuestion/submit',
    userSubmission: 'http://localhost:1337/api/submitQuestion/usersubmission',
    likeQuestion: 'http://localhost:1337/api/questionList/likeQuestion',
    fetchRecentSubmissions: 'http://localhost:1337/api/submitQuestion/recentSubmissions',
    fetchUpcomingContest: 'http://localhost:1337/api/contest/upcoming',
    fetchContestRewind: 'http://localhost:1337/api/contest/contestRewind',
    fetchGlobalRankSummary: 'http://localhost:1337/api/contest/globalRankingSummary',
    fetchContestDetail: 'http://localhost:1337/api/contest/contestDetail'
}

export default process.env.NODE_ENV === 'production' ? productionUrls : developmentUrls
