import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { RootState } from '../store'
import serverLinks from '../../Constants'
import IQuestionList from '../../interfaces/Question'
import IQuestionPage from '../../interfaces/Question'
import { createSelector } from '@reduxjs/toolkit'

export type initalStateType = {
    questionList: IQuestionList[]
    subjectFilter: 'dilr' | 'varc' | 'qa' | 'all'
    tagFilter: null | string
    currentQuestion: IQuestionPage | null
    isQuestionListLoading: boolean
    isQuestionPageLoading: boolean
    currentPage: number
    searchQuery: string
}

const initialState: initalStateType = {
    questionList: [],
    subjectFilter: 'all',
    tagFilter: null,
    currentQuestion: null,
    isQuestionListLoading: true,
    isQuestionPageLoading: true,
    currentPage: 1,
    searchQuery: ''
}

export type dataForQuestionFetch = {
    questionId: string
}

export const fetchQuestionList = createAsyncThunk('question/fetchQuestionList', async () => {
    const result = fetch(serverLinks.questionList)
        .then((response) => response.json())
        .catch((error) => console.error(error))
    return result
})

export const fetchCurrentQuestion = createAsyncThunk('question/fetchCurrentQuestion', async (questionId: string) => {
    const result = fetch(serverLinks.question, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            questionId: questionId
        })
    })
        .then((response) => response.json())
        .catch((error) => console.error(error))

    return result
})

export const likeCurrentQuestion = createAsyncThunk('question/likeQuestion', async (questionId: string) => {
    const result = fetch(serverLinks.likeQuestion, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            questionId: questionId
        })
    })
        .then((response) => response.json())
        .catch((error) => console.error(error))

    return result
})

const questionSlice = createSlice({
    name: 'question',
    initialState,
    reducers: {
        resetCurrentQuestion: (state) => {
            state.currentQuestion = null
        },
        setSubjectFilter: (state, action) => {
            state.subjectFilter = action.payload
        },
        setSearchQuery: (state, action) => {
            state.searchQuery = action.payload
        },
        toggleTagFilter: (state, action) => {
            if (action.payload.tag === state.tagFilter) {
                state.tagFilter = null
                state.subjectFilter = 'all'
            } else {
                state.subjectFilter = action.payload.subject
                state.tagFilter = action.payload.tag
            }
        },
        updateLikeCount: (state, action) => {
            if (state.currentQuestion) {
                if (state.currentQuestion.userLiked) {
                    state.currentQuestion.qLikes -= 1
                } else {
                    state.currentQuestion.qLikes += 1
                }
                state.currentQuestion.userLiked = !state.currentQuestion.userLiked
            }
        },
        updateCurrentPage: (state, action) => {
            state.currentPage = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchQuestionList.pending, (state) => {
            state.isQuestionListLoading = true
        })

        builder.addCase(fetchQuestionList.fulfilled, (state, action) => {
            if (action.payload && action.payload.questionList) {
                state.questionList = action.payload.questionList
            } else {
                console.error('Error occured when fetching Question ', action.payload)
            }
            state.isQuestionListLoading = false
        })

        builder.addCase(fetchQuestionList.rejected, (state) => {
            console.error('Failed to load the list of questions')
            state.isQuestionListLoading = false
        })

        builder.addCase(fetchCurrentQuestion.pending, (state) => {
            state.currentQuestion = null
            state.isQuestionPageLoading = true
        })

        builder.addCase(fetchCurrentQuestion.fulfilled, (state, action) => {
            try {
                state.currentQuestion = action.payload.questionData
            } catch (err) {
                console.error('Unable to fetch data for question', err)
            }
            state.isQuestionPageLoading = false
        })

        builder.addCase(fetchCurrentQuestion.rejected, (state) => {
            console.error('Failed to load current question')
            state.isQuestionPageLoading = false
        })
    }
})

// exporting actions
export const { resetCurrentQuestion, setSubjectFilter, setSearchQuery, toggleTagFilter, updateLikeCount, updateCurrentPage } = questionSlice.actions

// Selectors

const selectQuestionList = (state: RootState) => state.questions.questionList
const selectSubjectFilter = (state: RootState) => state.questions.subjectFilter
const selectSearchQuery = (state: RootState) => state.questions.searchQuery

// This is a memoized selector - using reselect - createSelector (comes with redux toolkit) -> memoized based on the output of the input selectors (selectQuestionList and selectSubjectFilter)
export const filteredQuestionList = createSelector(selectQuestionList, selectSubjectFilter, selectSearchQuery, (questionList: IQuestionList[], subjectFilter: string, searchQuery: string) => {
    let filteredList: IQuestionList[] = questionList

    // Subject Wise Filter
    if (subjectFilter === 'all') {

    } else {
        filteredList = questionList.filter((question) => {
            return question.qSubject === subjectFilter
        })

    }

    // Search Query Filter
    /* 
        TODO: make this a fuzzy search later on, as of now it just checks for exact match
        So right now the order of search also matters - which should ideally not be the case!
    */
    if (searchQuery === '') {

    } else {
        filteredList = filteredList.filter((question) => {
            const lowerCaseQuestion = question.qTitle.toLowerCase()
            return lowerCaseQuestion.includes(searchQuery)
        })
    }

    return filteredList
})

export const selectCurrentQuestion = (state: RootState) => state.questions.currentQuestion

export const selectCurrentQuestionSolution = (state: RootState) => state.questions.currentQuestion?.qSolution

export const selectCurrentQuestionExplanation = (state: RootState) => state.questions.currentQuestion?.qExplanation

export const selectIsQuestionPageLoading = (state: RootState) => state.questions.isQuestionPageLoading

export const selectCurrentPage = (state: RootState) => state.questions.currentPage

export const selectIsQuestionListLoading = (state: RootState) => state.questions.isQuestionListLoading

export default questionSlice.reducer
