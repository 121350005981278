import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { RootState } from '../store'
import serverLinks from '../../Constants'
import { currentSubmissionType, ISubmitQuestion, recentSubmissionsType } from '../../interfaces/Submission'

interface submissionState {
    submissionList: ISubmitQuestion[]
    currentSubmission: currentSubmissionType
    recentSubmissions: recentSubmissionsType[]
    isSubmissionLoading: boolean
}

const initialState: submissionState = {
    submissionList: [],
    currentSubmission: {
        optionSelected: '',
        questionId: ''
    },
    recentSubmissions: [],
    isSubmissionLoading: true
}

export type dataForSubmissionFetch = {
    questionId: string
    selectedOption: any
    timeMinutes: number
    timeSeconds: number
}

export const userSubmit = createAsyncThunk('submission/userSubmit', async (inputData: dataForSubmissionFetch) => {
    const { questionId, selectedOption, timeMinutes, timeSeconds } = inputData

    const result = await fetch(serverLinks.submitQuestion, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-type': 'application/json'
        },
        body: JSON.stringify({
            optionSelected: selectedOption,
            timeTaken: {
                minutes: timeMinutes,
                seconds: timeSeconds
            },
            questionId: questionId
        })
    })
        .then((response) => {
            return response.json()
        })
        .catch((error) => console.error(error))

    return result
})

export const fetchSubmissionHistory = createAsyncThunk('submission/fetchSubmissionHistory', async (questionId: string) => {
    const result = fetch(serverLinks.userSubmission, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            questionId: questionId
        })
    }).then((response) => {
        return response.json()
    })

    return result
})

export const fetchRecentSubmissions = createAsyncThunk('submission/fetchRecentSubmissions', async () => {
    const result = fetch(serverLinks.fetchRecentSubmissions, {
        credentials: 'include'
    }).then((response) => response.json())

    return result
})

const submissionSlice = createSlice({
    name: 'submission',
    initialState,
    reducers: {
        resetCurrentSubmission: (state) => {
            state.currentSubmission = {
                optionSelected: '',
                questionId: ''
            }
        },
        setCurrentSubmission: (state, action) => {
            state.currentSubmission = {
                optionSelected: action.payload.optionSelected,
                questionId: action.payload.questionId
            }
            state.isSubmissionLoading = true
        }
    },
    extraReducers: (builder) => {
        builder.addCase(userSubmit.fulfilled, (state) => {
            state.isSubmissionLoading = false
        })
        builder.addCase(userSubmit.rejected, (state) => {
            state.isSubmissionLoading = false
        })
        builder.addCase(fetchSubmissionHistory.fulfilled, (state, action) => {
            if (action.payload.submissions) {
                state.submissionList = action.payload.submissions
            } else {
                state.submissionList = []
                console.error('Could not load user submissions', action.payload)
            }
            state.isSubmissionLoading = false
        })
        builder.addCase(fetchSubmissionHistory.rejected, (state, action) => {
            console.error('Failed to fetch submission history', action.payload)
            state.isSubmissionLoading = false
        })

        builder.addCase(fetchRecentSubmissions.fulfilled, (state, action) => {
            if (action.payload) {
                if (action.payload.recentSubmissions) {
                    state.recentSubmissions = action.payload.recentSubmissions
                } else {
                    console.error('Unable to fetch recent user submissions', action.payload)
                    state.recentSubmissions = []
                }
            } else {
                console.error('Unable to fetch recent user submissions')
                state.recentSubmissions = []
            }
        })

        builder.addCase(fetchRecentSubmissions.rejected, () => {
            console.error('Unable to fetch recent user submissions')
        })
    }
})

export const { resetCurrentSubmission, setCurrentSubmission } = submissionSlice.actions

export const selectSubmissions = (state: RootState) => state.submissions.submissionList

export const selectCurrentSubmission = (state: RootState) => state.submissions.currentSubmission

export const selectIsSubmissionLoading = (state: RootState) => state.submissions.isSubmissionLoading

export const selectRecentSubmissions = (state: RootState) => state.submissions.recentSubmissions

export default submissionSlice.reducer
