import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { RootState } from '../store'
import serverLinks from '../../Constants'

export interface userState {
    isAuthenticated: boolean
    _id: string
    googleId: string
    firstName: string
    lastName: string
    displayName: string
    profilePicture: string
    emailId: string
    correctSubmissions: string[]
    error: null | 'unable to load user' | 'user not in session'
}

export const initialState: userState = {
    isAuthenticated: false,
    _id: '',
    googleId: '',
    firstName: '',
    lastName: '',
    displayName: '',
    profilePicture: '',
    emailId: '',
    correctSubmissions: [],
    error: null
}

export const fetchUser = createAsyncThunk('user/fetchUser', async () => {
    const result = await fetch(serverLinks.getUser, { credentials: 'include' })
    return result.json()
})

export const logoutUser = createAsyncThunk('user/logout', async () => {
    fetch(serverLinks.userLogout, { credentials: 'include' })
})

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchUser.fulfilled, (state, action) => {
            if (action.payload && action.payload.user) {
                state.isAuthenticated = true
                state._id = action.payload.user._id
                state.googleId = action.payload.user.googleId
                state.firstName = action.payload.user.firstName
                state.lastName = action.payload.user.secondName
                state.displayName = action.payload.user.displayName
                state.profilePicture = action.payload.user.image
                state.emailId = action.payload.user.emailId
                state.correctSubmissions = action.payload.user.correctSubmissions
                state.error = null
            } else {
                state.isAuthenticated = false
                state._id = ''
                state.googleId = ''
                state.firstName = ''
                state.lastName = ''
                state.displayName = ''
                state.profilePicture = ''
                state.emailId = ''
                state.correctSubmissions = []
                state.error = 'user not in session'
            }
        })

        builder.addCase(fetchUser.rejected, (state, action) => {
            state.error = 'unable to load user'
        })

        builder.addCase(logoutUser.fulfilled, (state) => {
            state.isAuthenticated = false
            state._id = ''
            state.googleId = ''
            state.firstName = ''
            state.lastName = ''
            state.displayName = ''
            state.profilePicture = ''
            state.emailId = ''
            state.correctSubmissions = []
            state.error = null
        })
    }
})

// export const { } = userSlice.actions

export const selectProfileProblemsSovled = (state: RootState) => {
    let requiredOutput = {
        numberOfQuestions: 0,
        numberOfQuestionsSolved: 0,
        numberOfQAQuestionsSolved: 0,
        numberOfDILRQuestionsSolved: 0,
        numberOfVARCQuestionsSolved: 0,
        numberOfQAQuestions: 0,
        numberOfDILRQuestions: 0,
        numberOfVARCQuestions: 0
    }

    if (state.user.isAuthenticated) {
        requiredOutput.numberOfQuestions = state.questions.questionList.length
        requiredOutput.numberOfQuestionsSolved = state.user.correctSubmissions.length
        if (state.questions.questionList) {
            const correctSubmissionsSet = new Set(state.user.correctSubmissions)

            for (let i = 0; i < state.questions.questionList.length; i++) {
                switch (state.questions.questionList[i].qSubject) {
                    case 'qa':
                        requiredOutput.numberOfQAQuestions++
                        break
                    case 'dilr':
                        requiredOutput.numberOfDILRQuestions++
                        break
                    case 'varc':
                        requiredOutput.numberOfVARCQuestions++
                }
                if (correctSubmissionsSet.has(state.questions.questionList[i]._id)) {
                    switch (state.questions.questionList[i].qSubject) {
                        case 'qa':
                            requiredOutput.numberOfQAQuestionsSolved++
                            break
                        case 'dilr':
                            requiredOutput.numberOfDILRQuestionsSolved++
                            break
                        case 'varc':
                            requiredOutput.numberOfVARCQuestionsSolved++
                    }
                }
            }
        }
    }

    return requiredOutput
}

export const selectUser = (state: RootState) => state.user

export const userCorrectSubmissionHistory = (state: RootState) => state.user.correctSubmissions

export const selectIsUserAuthenticated = (state: RootState) => state.user.isAuthenticated

export default userSlice.reducer
