import './App.css'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { useEffect, lazy, Suspense } from 'react'
import { useAppDispatch, useAppSelector } from './app/hooks'
import { fetchUser } from './app/reducers/user'
import { fetchQuestionList } from './app/reducers/question'
import { selectDarkMode, checkDarkMode } from './app/reducers/global'

// Google Analytics
import ReactGA from 'react-ga'
import { TRACKING_ID } from './Constants'

// Non-lazy loaded pages
import NotFound from './pages/NotFound'
import ErrorBoundary from './components/ErrorBoundary'
import Loader from './components/Loader'
import darkModeToggle from './utils/darkModeToggle'

// Lazily Loading other pages
const Home = lazy(() => import(/* webpackChunkName: "home-page" */ './pages/Home'))
const NavBar = lazy(() => import(/* webpackChunkName: "navbar" */ './components/NavBar'))
const Question = lazy(() => import(/* webpackChunkName: "question-page" */ './pages/Question'))
const ContestLanding = lazy(() => import(/* webpackChunkName: "contest-page" */ './pages/ContestLanding'))
const ComingSoon = lazy(() => import(/* webpackChunkName: "comingsoon-page" */ './pages/ComingSoon'))
const Profile = lazy(() => import(/* webpackChunkName: "profile-page" */ './pages/Profile'))
const LiveContest = lazy(() => import(/* webpackChunkName: "live-contest-page" */ './pages/LiveContest'))
const PricingPage = lazy(() => import(/* webpackChunkName: "live-contest-page" */ './pages/Pricing'))

// Tracking
ReactGA.initialize(TRACKING_ID)

const App = () => {
    const dispatch = useAppDispatch()
    const isDarkMode = useAppSelector(selectDarkMode)

    useEffect(() => {
        dispatch(fetchUser())
        dispatch(fetchQuestionList())
        ReactGA.pageview(window.location.pathname + window.location.search)
        dispatch(checkDarkMode())
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        darkModeToggle()
    }, [isDarkMode])

    return (
        <div className="App dark:bg-slate-800">
            <ErrorBoundary>
                <Suspense fallback={<Loader />}>
                    <Router>
                        <NavBar />
                        <div className="pt-16"></div>
                        <Switch>
                            <Route path="/question/:id">
                                <Question />
                            </Route>
                            <Route path="/contest">
                                <ContestLanding />
                            </Route>
                            <Route path="/profile">
                                <Profile />
                            </Route>
                            <Route path="/comingsoon">
                                <ComingSoon />
                            </Route>
                            <Route path="/livecontest/:id" exact>
                                <LiveContest />
                            </Route>
                            <Route path="/pricing">
                                <PricingPage />
                            </Route>
                            <Route path="/" exact>
                                <Home />
                            </Route>
                            <Route path="*">
                                <NotFound />
                            </Route>
                        </Switch>
                    </Router>
                </Suspense>
            </ErrorBoundary>
        </div>
    )
}

export default App
